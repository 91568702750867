import React from "react";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import MainBanner from "../components/RutherfordLabs/MainBanner";
import OurSolutions from "../components/RutherfordLabs/OurSolutions";
import AboutArea from "../components/RutherfordLabs/AboutArea";
import OurMission from "../components/RutherfordLabs/OurMission";
import Funfacts from "../components/RutherfordLabs/Funfacts";
import UpcomingBootcamps from "../components/RutherfordLabs/UpcomingBootcamps";
import HowItWork from "../components/RutherfordLabs/HowItWork";
import RecentProjects from "../components/RutherfordLabs/RecentProjects";
import TeamMembers from "../components/RutherfordLabs/TeamMembers";
import Testimonials from "../components/RutherfordLabs/Testimonials";
import Partner from "../components/RutherfordLabs/Partner";
import BlogPost from "../components/RutherfordLabs/BlogPost";
import StartProject from "../components/Common/StartProject";

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <OurMission />
      {/* <MainBanner /> */}
      <OurSolutions />
      {/* <AboutArea /> */}  
      <Funfacts />
      {/* <UpcomingBootcamps /> */}
      <HowItWork />
      {/* <RecentProjects /> */}
      {/* <TeamMembers /> */}
      {/* <Testimonials /> */}
      {/* <Partner /> */}
      {/* <BlogPost /> */}
      {/* <StartProject /> */}
      {/* <Footer /> */}
    </Layout>
  );
};

export default Home;
