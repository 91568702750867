import React from 'react';
import { Link } from 'gatsby';

import StarIcon from '../../assets/images/star-icon.png';

const OurSolutions = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={StarIcon} alt="icon" /> 
                        Our Solutions
                    </span>
                    <h2>Advanced Technology Solutions for Modern Challenges</h2>
                    <p>
                        At VocalBrain, we harness the power of advanced technology to create data-driven solutions that empower businesses. Our expertise in AI, machine learning, cloud infrastructure, and application development enables us to transform complex data into strategic insights. Whether you’re aiming to optimize performance, enhance decision-making, or accelerate innovation, our solutions are designed to make an impact.
                    </p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-ai"></i>
                            </div>
                            <h3>AI & Machine Learning</h3>
                            <p>
                                Leveraging frameworks like TensorFlow and scikit-learn, we develop intelligent systems that adapt and learn from data. Our AI solutions empower organizations to automate processes, predict trends, and make data-driven decisions with confidence.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-cloud"></i>
                            </div>

                            <h3>Cloud Infrastructure with GCP & Firebase</h3>
                            <p>
                                Built on Google Cloud Platform and Firebase, our cloud infrastructure solutions offer robust, scalable, and secure environments. From data storage and real-time analytics to application deployment, we help businesses harness the full power of cloud technology.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-app"></i>
                            </div>

                            <h3>Application Development with React & Flutter</h3> 
                            <p>
                                Our team specializes in building high-performance applications using React for web and Flutter for cross-platform mobile development. From user-friendly interfaces to seamless functionality, we create apps that engage and empower users.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OurSolutions;
